import React, { memo } from 'react';

import * as Icons from './icons';

export enum IconsNames {
  arrow_white = 'arrow_white',
  checkMark = 'checkMark',
  close = 'close',
  telegram_yellow = 'telegram_yellow',
  logo = 'logo',
  phone = 'phone',
  mir = 'mir',
  maestro = 'maestro',
  mastercard = 'mastercard',
  visa = 'visa',
  star = 'star',
  common_cover = 'common_cover',
  design_cover = 'design_cover',
  design_cover1 = 'design_cover1',
  design_cover2 = 'design_cover2',
  discount50 = 'discount50',
  more_benefit = 'more_benefit',
  benefit = 'benefit',
  benefit800 = 'benefit800',
  benefit900 = 'benefit900',
  benefit500 = 'benefit500',
  benefit350 = 'benefit350',
  benefit550 = 'benefit550',
  benefit300 = 'benefit300',
  benefit600 = 'benefit600',
  benefit620 = 'benefit620',
  benefit260 = 'benefit260',
  benefit450 = 'benefit450',
  benefit700 = 'benefit700',
  benefit400 = 'benefit400',
  benefit650 = 'benefit650',
  benefit750 = 'benefit750',
  benefit1100 = 'benefit1100',
  benefit1 = 'benefit1',
  gift_box = 'gift_box',
  fire = 'fire',
  timer = 'timer',
  frontend = 'frontend',
  web = 'web',
  unreal_engine = 'unreal_engine',
  android = 'android',
  qa_csharp = 'qa_csharp',
  unity = 'unity',
  devops = 'devops',
  pa = 'pa',
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
  cs_ba = 'cs_ba',
  cs_3d = 'cs_3d',
  cs_ds = 'cs_ds',
  cs_manual_qa = 'cs_manual_qa',
  cs_python = 'cs_python',
  cs_gamedesign = 'cs_gamedesign',
  cs_motion = 'cs_motion',
  cs_cyber = 'cs_cyber',
  cs_react = 'cs_react',
  cs_system = 'cs_system',
  cs_designer = 'cs_designer',
  cs_go = 'cs_go',
  cs_bi = 'cs_bi',
  cs_csharp = 'cs_csharp',
  cs_java = 'cs_java',
  cs_hr = 'cs_hr',
  cs_ios = 'cs_ios',
  cs_android = 'cs_android',
  cs_pdm = 'cs_pdm',
  cs_qa_js = 'cs_qa_js',
  cs_project = 'cs_project',
  cs_qa_python = 'cs_qa_python',
  cs_uxui = 'cs_uxui',
  cs_unity = 'cs_unity',
  cs_qa_java = 'cs_qa_java',
  cs_pa = 'cs_pa',
  cs_unreal_engine = 'cs_unreal_engine',
  cs_qa_csharp = 'cs_qa_csharp',
  cs_web = 'cs_web',
  cs_fe = 'cs_fe',
  cs_devops = 'cs_devops',
  cs_one_c = 'cs_one_c',
  cs_data_engineer = 'cs_data_engineer',
  cs_person = 'cs_person',
  cs_flutter = 'cs_flutter',
  cs_ml = 'cs_ml',
  cs_sre = 'cs_sre',
}

export interface IIconProps {
  name: IconsNames;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = memo(
  ({ name, width = '100%', height = '100%', color, className }: IIconProps) => {
    const IconComponent = IconsMap[name];

    return (
      <IconComponent
        width={width}
        height={height}
        fill={color}
        className={className}
      />
    );
  },
);
