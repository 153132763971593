import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import {
  // DailyCounter,
  Icon,
  IconsNames,
  InfoCard,
  InfoCardsTablet,
  LinkArrow,
  SaleOfferCard,
  GiftBlock,
  StickyCounter,
} from 'components/atoms';
import { MainForm, Hits } from 'components/molecules';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { PATHS } from 'router/config';

// const DayOfferText = memo(() => (
//   <SecondaryTitle>
//     <YellowText>+10% скидка</YellowText> на все курсы
//     <Arrow name={IconsNames.arrow_white} width={16} height={16} />
//   </SecondaryTitle>
// ));

const CoursesOnSale = memo(() => (
  <SecondaryTitle>
    Выбрать курс <br /> со скидкой до <br /> <YellowText>1 000 BYN</YellowText>
    <Arrow name={IconsNames.arrow_white} width={16} height={16} />
  </SecondaryTitle>
));

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>Чёрная пятница</Title>
          <Subtitle>
            Скидки до <br />
            <HighlightedText>- 1 000 BYN</HighlightedText>
          </Subtitle>
          <AdditionalInfo>
            Запишись на IT курсы TeachMeSkills с беспрецендетной скидкой до 1{' '}
            000 BYN с 11 ноября 2024 по 30 ноября 2024
          </AdditionalInfo>

          <GiftBlock />
        </MainInfoWrapper>

        <InfoCardsTabletWrapper>
          <InfoCardsTablet />
        </InfoCardsTabletWrapper>

        <SaleCourses>
          <SaleOfferCard
            path={PATHS.courses}
            title={<CoursesOnSale />}
            infoText="выбрать курс"
            image={IconsNames.common_cover}
          />
          <AdditionalOffer>
            <Info>Дополнительное предложение</Info>
            <SecondaryTitle>
              <YellowText>+10% скидка</YellowText>
              <br /> на все курсы
            </SecondaryTitle>
            <AdditionalWrapper>
              <Text>При подтверждении заявки в течение 12 часов</Text>
              <Sticker>
                <Icon name={IconsNames.more_benefit} width={150} height={150} />
              </Sticker>
            </AdditionalWrapper>
            <IconStyled name={IconsNames.design_cover1} />
          </AdditionalOffer>
          <AdditionalOffer>
            <Info>Дополнительное предложение</Info>
            <SecondaryTitle>
              Второй курс со <br />
              <YellowText>скидкой 50%</YellowText>
            </SecondaryTitle>
            <AdditionalWrapper>
              <Text>Воспользоваться обучением можно в течение 2025 года</Text>
              <Sticker>
                <Icon name={IconsNames.more_benefit} width={150} height={150} />
              </Sticker>
            </AdditionalWrapper>
            <IconStyled name={IconsNames.design_cover2} />
          </AdditionalOffer>

          {/*   <SaleOfferCard */}
          {/*     path={`${PATHS.courses}?filter=dayOffer`} */}
          {/*     title={<DayOfferText />} */}
          {/*     infoText="предложение дня" */}
          {/*     image={IconsNames.design_cover} */}
          {/*   > */}
          {/*     <AdditionalWrapper> */}
          {/*       <DailyCounter /> */}
          {/*       <Text>Предложение действует только сегодня!</Text> */}
          {/*       <Sticker> */}
          {/*         <Icon name={IconsNames.more_benefit} width={150} height={150} /> */}
          {/*       </Sticker> */}
          {/*     </AdditionalWrapper> */}
          {/*   </SaleOfferCard> */}
        </SaleCourses>

        <HitCourses>
          <HitCoursesHead>
            <SecondaryTitle>Хиты продаж</SecondaryTitle>
            <LinkArrow title="Смотреть все" link={`${PATHS.courses}`} />
          </HitCoursesHead>

          <Hits />
        </HitCourses>
      </Content>
      <FormWrapper ref={formRef}>
        <MainForm />
        <StyledInfoCard />
      </FormWrapper>

      <StickyCounter formRef={formRef} />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 18px 0 80px;
  }

  ${desktop} {
    margin: 118px 0 96px;
  }
`;

const InfoCardsTabletWrapper = styled.div`
  display: none;

  ${tablet} {
    display: flex;
    margin-bottom: 80px;
  }

  ${desktop} {
    display: none;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 68px;
    line-height: 80px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 56px;
    line-height: 80px;
    margin-bottom: 32px;
  }
`;

const SecondaryTitle = styled.h3`
  width: 68%;
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;
  z-index: 1;

  ${tablet} {
    width: 100%;
    font-size: 32px;
    line-height: 44px;
  }
`;

const HighlightedText = styled.span`
  color: ${ColorService.YELLOW};
  font-size: 48px;
  line-height: 56px;

  ${tablet} {
    font-size: 80px;
    line-height: 96px;
  }
`;

const AdditionalInfo = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 56px;
  max-width: 322px;

  ${tablet} {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 64px;
    max-width: 448px;
  }

  ${desktop} {
    max-width: unset;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${desktop} {
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 54px;
  }
`;

const SaleCourses = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    margin-bottom: 80px;
    gap: 20px;
  }
`;

const AdditionalWrapper = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 1;

  ${tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const Sticker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
`;

const YellowText = styled.span`
  color: ${ColorService.YELLOW};
`;

const Text = styled.p`
  max-width: 170px;
  font-size: 12px;
  line-height: 18px;
  color: ${ColorService.GRAY};
`;

const Arrow = styled(Icon)`
  margin-left: 8px;
`;

const HitCourses = styled.div``;

const HitCoursesHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  display: none;

  ${desktop} {
    display: unset;
  }
`;

const AdditionalOffer = styled.div`
  position: relative;
  background-color: ${ColorService.BG_GRAY};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  width: 100%;
  height: 338px;
  transition: 0.6s;
  overflow: hidden;

  ${tablet} {
    height: 332px;
  }
`;

const Info = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
  color: ${ColorService.GRAY};
  text-transform: uppercase;
  margin-bottom: 8px;
  z-index: 1;
`;

const IconStyled = styled(Icon)`
  height: 96%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  border-radius: 24px;
  right: 0;
  bottom: 0;

  ${tablet} {
    height: 146%;
  }
`;
