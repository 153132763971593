import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, getFontFamily } from 'services';
import { Icon, IconsNames } from 'components/atoms';

interface IInfoCardProps {
  className?: string;
}

export const InfoCard = memo(({ className }: IInfoCardProps) => (
  <Wrapper className={className}>
    <Rating>
      4,9 <Icon name={IconsNames.star} width={32} height={32} />
    </Rating>
    <InfoText>
      На основании более <BoldText>1000+ отзывов</BoldText> на Google, Yandex,
      Relax, TutorTop и др. независмых площадках.
    </InfoText>
    <Graduation>12 000+</Graduation>
    <InfoText>
      Выпустили айтишников, которые успешно начали свой путь в международных IT
      компаниях.
    </InfoText>
  </Wrapper>
));

const Wrapper = styled.div`
  background-color: ${ColorService.BG_GRAY};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  border: 1px solid ${ColorService.SILVER};
  width: 100%;

  ${desktop} {
    width: 446px;
  }
`;

const Rating = styled.h4`
  font-size: 32px;
  line-height: 44px;
  font-family: ${getFontFamily('bold')};
  color: ${ColorService.YELLOW};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

const Graduation = styled.h4`
  color: ${ColorService.WHITE};
  font-size: 32px;
  line-height: 44px;
  font-family: ${getFontFamily('bold')};
  margin: 20px 0 4px;
`;

const InfoText = styled.p`
  color: ${ColorService.WHITE};
  font-size: 12px;
  line-height: 18px;
`;

const BoldText = styled.span`
  font-size: 14px;
  font-family: ${getFontFamily('bold')};
`;
