export enum PATHS {
  main = '/',
  courses = '/courses',
  course = '/courses/:courseName/*',
  frontend = '/courses/frontend',
  python = '/courses/python',
  java = '/courses/java',
  ios = '/courses/ios',
  android = '/courses/android',
  fullstack_csharp = '/courses/fullstack_csharp',
  unity = '/courses/unity',
  unreal_engine = '/courses/unreal_engine',
  game_design = '/courses/game_design',
  web = '/courses/web',
  manual_qa = '/courses/manual_qa',
  qa_csharp = '/courses/qa_csharp',
  qa_python = '/courses/qa_python',
  qa_java = '/courses/qa_java',
  qa_js = '/courses/qa_js',
  project_manager = '/courses/project_manager',
  business_analyst = '/courses/business_analyst',
  business_intelligence = '/courses/business_intelligence',
  game_dev = '/courses/game_dev',
  uxui = '/courses/uxui',
  motion = '/courses/motion',
  ds = '/courses/ds',
  devops = '/courses/devops',
  product_manager = '/courses/product_manager',
  product_analyst = '/courses/product_analyst',
  hr = '/courses/hr',
  go = '/courses/go',
  cyber = '/courses/cyber',
  react_native = '/courses/react_native',
  designer = '/courses/designer',
  system_analyst = '/courses/system_analyst',
  data_engineer = '/courses/data_engineer',
  flutter = '/courses/flutter',
  one_c = '/courses/one_c',
  person = '/courses/person',
  ml = '/courses/ml',
  sre = '/courses/sre',

  privacy = 'https://teachmeskills.ru/politika-obrabotki-personalnyh-dannyh',
  vk = 'https://vk.com/teachmeskills',
  telegram = 'https://t.me/TMS_news',
  youtube = 'https://www.youtube.com/channel/UCrpbZ8VZjn8FtMv0-I1VJ2A',
  career = 'https://teachmeskills.by/career-center',
  // prof = 'https://teachmeskills.by/proforientation',
  blog = 'https://teachmeskills.by/blog',
}
